import React from "react"
import { graphql } from "gatsby"

import { labels } from '../langs/langs'

import Layout from "../components/layout"
import Seo from "../components/seo"
import WpPage from "../components/WpPage"

const StoresArchivePage = ({ data, location }) => {

    const localeID = 'en_US';

    return (
        <Layout location={location} localeID={localeID} navShadow={true}>
            <Seo title={labels[localeID]["MARKET_REG"]} />
            <WpPage localeID={localeID} data={data} url={location.pathname}/>
        </Layout>
    )
  }
  
export default StoresArchivePage

export const pageQuery = graphql`
    query MarketRegPageEN {
        wpPage(slug: {eq: "market-regulations"}, locale: {id: {eq: "en_US"}}) {
            title
            content
        }
    }
`